import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  background: linear-gradient(135deg, #0079bf, #5067c5);
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
`;

export const TwitterImage = styled.img`
  width: 16px;
`;

export const Link = styled.a`
  color: white;
  :hover {
    color: white;
  }
`;

export const Heading = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Copyright = styled.div`
  font-size: 12px;
  margin-top: 10px;
  padding-bottom: 15px;
`;
