import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  Box,
  Container,
  Content,
  DemoLink,
  Description,
  FormContainer,
  HeroImage,
  MainHeading,
  Row,
  StartButton,
  Wrapper,
} from './styles';
import heroImage from '../../../images/heroImage.png';
import { createNewBoard } from '../../../apis/apis';

const Hero: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const handleHomeButton = () => {
    createNewBoard(email).then(res => {
      window.location.href = `${window.location.protocol}//${window.location.host}/dashboard/${res.data.id}`;
    });
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Box>
            <Content>
              <MainHeading>Keep your job search organized.</MainHeading>
              <Description>
                funnel.fyi helps you keep track of all your job applications and helps you visualize
                your search, and make decisions based on data.
              </Description>
            </Content>
          </Box>
          <Box>
            <HeroImage src={heroImage} />
          </Box>
        </Row>
        <Row>
          <FormContainer>
            <Form>
              <Form.Group>
                <Form.Control
                  onChange={e => setEmail(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Enter your email."
                />
              </Form.Group>
            </Form>
          </FormContainer>
        </Row>
        <Row>
          <StartButton>
            <Button className="hero-button" variant="success" size="lg" onClick={handleHomeButton}>
              Start your funnel — it&apos;s free!
            </Button>
          </StartButton>
        </Row>
        <Row>
          <DemoLink href="/dashboard/fe000528-6613-461a-98c4-f65a25c8d73c">or try a demo</DemoLink>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Hero;
