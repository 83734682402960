import React from 'react';
import Header from '../../components/Header';
import Footer from '../Home/Footer';
import { Posts } from './Posts';

export const Blog: React.FC = () => {
  return (
    <>
      <Header />
      <Posts />
      <Footer />
    </>
  );
};
