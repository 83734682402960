import React, { useEffect, useState } from 'react';
import Editor from 'rich-markdown-editor';
import styled from 'styled-components';

import { getCardDetails } from '../../apis/apis';
import { CardDetails } from './types';
import { light } from './EditorTheme';
import Loader from '../Loader';

type CardDetailsFormProps = {
  cardId: string;
  boardId: string;
  onChange: (any) => void;
};

export const EditorWrapper = styled.div`
  z-index: 2000;
  margin: 10px 20px;
`;

export const CardDetailsForm: React.FC<CardDetailsFormProps> = ({ cardId, boardId, onChange }) => {
  const [cardDetails, setCardDetails] = useState<CardDetails>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleCardDetailsFetch = (data: CardDetails) => {
    setCardDetails(data);
    setLoading(false);
    onChange({
      ...data,
      comment: '',
    });
  };
  useEffect(() => {
    getCardDetails(cardId, boardId, handleCardDetailsFetch);
  }, []);

  if (loading) {
    return <Loader height={50} />;
  }

  if (!cardDetails) return null;

  return (
    <>
      <p>
        <b>Company</b>: {cardDetails.title}
      </p>
      <hr />
      <p>
        <b>Job title</b>: {cardDetails.description}
      </p>
      <hr />
      <p>
        <b>Location</b>: {cardDetails.location}
      </p>
      <hr />
      <b>Notes: </b>
      <EditorWrapper>
        <Editor
          defaultValue={cardDetails.comment ? cardDetails.comment : ''}
          onChange={callback => {
            const comment = callback();
            onChange({
              ...cardDetails,
              comment,
            });
          }}
          theme={light}
          placeholder="Write up more details here."
        />
      </EditorWrapper>
    </>
  );
};
