import React, { useEffect } from 'react';
import styled from 'styled-components';

const PostsWrapper = styled.div`
  margin: 20px 220px;
  min-height: calc(100vh - 223px);
  font-family: 'Inconsolata', monospacel;

  @media (max-width: 1024px) {
    margin: 20px;
  }
`;

const Year = styled.div`
  font-size: 50px;
`;

const Link = styled.a`
  font-size: 20px;
`;

const BLOG_POSTS = {
  '2021': [
    {
      title: 'Getting started with Funnel.fyi',
      date: new Date(2021, 1, 6),
      link: '/blog/getting-started',
    },
  ],
};

type PostType = { link: string; title: string; date: Date };

const PostLink: React.FC<{ post: PostType }> = ({ post }) => {
  const { link, title } = post;
  return (
    <li>
      <Link href={link}>{title}</Link>
    </li>
  );
};

export const Posts = () => {
  useEffect(() => {
    document.title = 'Blog | Funnel.fyi';
  });

  return (
    <PostsWrapper>
      <h2 style={{ fontWeight: 700 }}>Welcome to the funnel.fyi blog!</h2>
      <hr />
      <h3 style={{ fontWeight: 700 }}>
        Follow us to get the latest updates on new features, career advice and much else.
      </h3>
      <hr />
      {Object.keys(BLOG_POSTS).map(year => {
        const yearPosts = BLOG_POSTS[year];
        return (
          <>
            <Year>{year}</Year>
            <ul>
              {yearPosts.map(post => (
                <PostLink post={post} />
              ))}
            </ul>
            <hr />
          </>
        );
      })}
    </PostsWrapper>
  );
};
