import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Board, { createTranslate } from 'react-trello';
import { createCard, getDashboard, deleteCard, moveCard } from '../../apis/apis';
import { IGetDashboardResponse } from '../../apis/types';
import CardDetailsModal from '../../components/CardDetailsModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import Loader from '../../components/Loader';
import { IDashboardRouteParams, IBoard, ICard } from './types';

const DEMO_BOARD_IDS = ['fe000528-6613-461a-98c4-f65a25c8d73c'];

const TRANSLATION_TABLE = {
  'Add another lane': '+ Add another lane',
  'Click to add card': 'Click to add new application',
  'Delete lane': 'x',
  'Lane actions': 'Lane actions',
  button: {
    'Add lane': 'Add lane',
    'Add card': 'Save Application',
    Cancel: 'Cancel',
  },
  placeholder: {
    title: 'Company',
    description: 'Job title',
    label: 'Location',
  },
};
const customTranslation = createTranslate(TRANSLATION_TABLE);

const Dashboard: React.FC = () => {
  const { dashboardId } = useParams<IDashboardRouteParams>();

  const [boardData, setBoardData] = useState<any>({}); // TODO(akhilesh): remove this any
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showCardDetailsModal, setShowCardDetailsModal] = useState<boolean>(false);
  const [clickedCard, setClickedCard] = useState<string>('');
  const [clickedCardLaneId, setClickedCardLaneId] = useState<string>('');
  const [deleteCallback, setDeleteCallback] = useState<any>();

  const handleGetDashboardData = (data: IGetDashboardResponse) => {
    setBoardData(data.data);
    setIsLoading(data.loading);
    if (data.error) setError(data.error);
  };

  useEffect(() => {
    getDashboard(dashboardId, handleGetDashboardData);
  }, []);

  const handleCardAdd = (card: ICard, laneId: string) => {
    if (DEMO_BOARD_IDS.includes(boardData.id)) return;
    createCard(card, laneId, boardData.id, () => {
      getDashboard(boardData.id, handleGetDashboardData);
    });
  };

  const handleCardDelete = (cardId: string, laneId: string) => {
    if (DEMO_BOARD_IDS.includes(boardData.id)) return;
    deleteCard(cardId, boardData.id, () => {
      getDashboard(boardData.id, handleGetDashboardData);
    });
  };

  const handleCardClick = (cardId: string, metadata: any, laneId: string) => {
    setClickedCard(cardId);
    setClickedCardLaneId(laneId);
    setShowCardDetailsModal(true);
  };

  const handleDataChange = (newData: IBoard) => {
    let data = newData;
    if (!newData.lanes) {
      data = { ...newData, lanes: [] };
    }
    if (data.lanes) {
      const updatedLanes = data.lanes.map(({ cards, ...rest }) => ({
        ...rest,
        cards,
        label: `${cards.length}`,
      }));
      setBoardData({ id: boardData.id, lanes: updatedLanes });
    }
  };

  const handleBeforeCardDelete = deleteCardCallback => {
    setShowConfirmationModal(true);
    setDeleteCallback(() => deleteCardCallback);
  };

  const handleCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
    if (DEMO_BOARD_IDS.includes(boardData.id)) return;
    moveCard(cardId, fromLaneId, toLaneId, boardData.id, () => {
      getDashboard(boardData.id, handleGetDashboardData);
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <Board
        t={customTranslation}
        style={{
          minHeight: 'calc(100vh - 70px)',
          paddingBottom: '15px',
          height: 'auto',
        }}
        data={boardData}
        editable
        onCardAdd={handleCardAdd}
        draggable
        laneDraggable={false}
        onDataChange={handleDataChange}
        onBeforeCardDelete={handleBeforeCardDelete}
        onCardDelete={handleCardDelete}
        onCardMoveAcrossLanes={handleCardMoveAcrossLanes}
        onCardClick={handleCardClick}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onPrimaryButtonClick={() => {
          deleteCallback();
          setShowConfirmationModal(false);
        }}
        onSecondaryButtonClick={() => setShowConfirmationModal(false)}
        title="Delete Card"
        body={
          <div>
            Are you sure you want to delete this card? <br />
            Hint: Maybe you want to move this to the Rejected lane instead.
          </div>
        }
      />
      <CardDetailsModal
        cardId={clickedCard}
        boardId={boardData.id}
        laneId={clickedCardLaneId}
        show={showCardDetailsModal}
        onClose={() => setShowCardDetailsModal(false)}
        primaryButtonText="Save card"
        secondaryButtonText="Cancel"
      />
    </>
  );
};

export default Dashboard;
