import React from 'react';
import { Box, Container, Content, Description, DashboardImage, Heading, Wrapper } from './styles';
import dashboardImage from '../../../images/dashboard.png';
import sankeyImage from '../../../images/sankey.png';
import barGraph from '../../../images/bargraph.png';

const Product: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <Box>
          <DashboardImage src={dashboardImage} />
        </Box>
        <Content>
          <Heading>Keep track of your job applications in a kanban board.</Heading>
          <Description>
            Quickly see how many places you&rsquo;ve applied, how many interviews you&rsquo;ve given
            and how many places you&rsquo;re yet to hear back from.
          </Description>
        </Content>
      </Container>
      <Container>
        <Content>
          <Heading>Supercharge your job search with data.</Heading>
          <Description>
            Use our analytics and diagrams to make informed decisions in your job search.
          </Description>
        </Content>
        <Box>
          <DashboardImage src={sankeyImage} />
        </Box>
      </Container>
      <Container>
        <Box>
          <DashboardImage src={barGraph} />
        </Box>
        <Content>
          <Heading>Understand how much work you&rsquo;re putting in.</Heading>
          <Description>
            Finding a job can be hard work. Keep yourself motivated by knowing exactly how many
            applications you are making every day.
          </Description>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Product;
