import styled from 'styled-components';

export const Wrapper = styled.section`
  margin-top: 3rem;
  margin-bottom: 5rem;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 480px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 40%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 3rem;
  margin-left: 1rem;
  @media (max-width: 480px) {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0;
    button {
      width: 100%;
    }
  }
`;

export const Heading = styled.h2`
  display: flex;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  @media (max-width: 480px) {
    font-size: 24px;
    text-align: center;
  }
`;

export const Description = styled.p`
  display: flex;
  font-size: 1.5625rem;
  font-weight: 300;
  @media (max-width: 480px) {
    font-size: 20px;
    text-align: center;
    line-height: 1.2;
  }
`;

export const DashboardImage = styled.img`
  width: 100%;
`;
