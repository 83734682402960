import React from 'react';
import { useParams } from 'react-router-dom';
import { IDashboardRouteParams } from '../Dashboard/types';
import { Sankey } from './Sankey';
import { BarGraph } from './BarGraph';

export const GraphPage: React.FC = () => {
  const { dashboardId } = useParams<IDashboardRouteParams>();
  return (
    <>
      <Sankey dashboardId={dashboardId} />
      <BarGraph dashboardId={dashboardId} />
    </>
  );
};
