import React, { useEffect, useState } from 'react';
import { ResponsiveSankey } from '@nivo/sankey';
import styled from 'styled-components';
import { getSankeyData } from '../../../apis/apis';
import { LANES } from '../../Dashboard/lanes';
import Loader from '../../../components/Loader';

const Container = styled.section`
  height: 500px;
`;

type SankeyProps = {
  dashboardId: string;
};

export const Sankey: React.FC<SankeyProps> = ({ dashboardId }) => {
  const [graphData, setGraphData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  const handleGraphData = (data, isLoading) => {
    setGraphData(data);
    setLoading(isLoading);
  };

  useEffect(() => {
    getSankeyData(dashboardId, handleGraphData);
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (graphData.nodes.length === 0 || graphData.links.length === 0) {
    return null;
  }

  console.log('All nodes', graphData.nodes);

  return (
    <Container>
      <ResponsiveSankey
        data={graphData}
        margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
        align="center"
        colors={node => {
          const possibleLanes = LANES.filter(lane => lane.title === node.id);
          if (possibleLanes.length > 0) {
            return possibleLanes[0].color;
          }
          return 'green';
        }}
        nodeOpacity={1}
        nodeThickness={18}
        nodeInnerPadding={3}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.1}
        enableLinkGradient
        labelPosition="outside"
        labelOrientation="vertical"
        labelPadding={16}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1]] }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            translateX: 130,
            itemWidth: 100,
            itemHeight: 14,
            itemDirection: 'right-to-left',
            itemsSpacing: 2,
            itemTextColor: '#999',
            symbolSize: 14,
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </Container>
  );
};
