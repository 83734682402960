import React, { useState } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { CardDetailsForm } from './CardDetailsForm';
import { CardDetailsModalProps, CardDetails } from './types';
import { updateCard } from '../../apis/apis';
import { ICard } from '../../containers/Dashboard/types';

const onSaveCardClick = ({ cardId, boardId, laneId, cardDetail, setSaved }) => {
  if (cardDetail === null) {
    return;
  }

  const card: ICard = {
    id: cardId,
    title: cardDetail.title,
    description: cardDetail.description,
    laneId,
    label: cardDetail.location,
    url: '',
    comment: cardDetail.comment,
  };

  updateCard(laneId, card, boardId);
  setSaved(true);
};

const CardDetailsModal: React.FC<CardDetailsModalProps> = ({
  cardId,
  boardId,
  show,
  onClose,
  secondaryButtonText,
  laneId,
}) => {
  const [cardDetail, setCardDetail] = useState<CardDetails | null>(null);
  const [saved, setSaved] = useState<boolean>(false);

  let saveButtonMessage = 'Save card details';
  if (saved) {
    saveButtonMessage = 'Saved!';
  }

  return (
    <>
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardDetailsForm
            cardId={cardId}
            boardId={boardId}
            onChange={details => {
              setCardDetail(details);
              setSaved(false);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {secondaryButtonText}
          </Button>
          <Button
            variant="primary"
            onClick={event => onSaveCardClick({ cardId, boardId, laneId, cardDetail, setSaved })}
          >
            {saveButtonMessage}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CardDetailsModal;
