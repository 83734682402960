import React from 'react';
import Header from '../../components/Header';
import Hero from './HeroSection';
import Product from './ProductSection';
import Footer from './Footer';

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <Product />
      <Footer />
    </>
  );
};

export default Home;
