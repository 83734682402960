import React from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboard, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { Logo, LogoTitle, Wrapper, BetaTag, GraphIcon, LogoWrapper, LinkWrapper } from './styles';
import { IDashboardRouteParams } from '../../containers/Dashboard/types';

const Header: React.FC = () => {
  const { dashboardId } = useParams<IDashboardRouteParams>();
  return (
    <Wrapper>
      <LogoWrapper href="/">
        <LogoTitle>funnel.fyi</LogoTitle>
        <BetaTag>BETA</BetaTag>
      </LogoWrapper>
      <LinkWrapper>
        <GraphIcon href="/blog">Blog</GraphIcon>
      </LinkWrapper>
      {dashboardId && (
        <LinkWrapper>
          <GraphIcon href={`/dashboard/${dashboardId}`}>
            <FontAwesomeIcon icon={faChalkboard} /> Dashboard
          </GraphIcon>
          <GraphIcon>|</GraphIcon>
          <GraphIcon href={`/dashboard/${dashboardId}/graphs`}>
            <FontAwesomeIcon icon={faChartPie} /> Graphs
          </GraphIcon>
        </LinkWrapper>
      )}
    </Wrapper>
  );
};

export default Header;
