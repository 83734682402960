import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { IConfirmationModalProps } from './types';

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  show,
  onClose,
  title,
  body,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText = 'Yes',
  secondaryButtonText = 'No',
}) => {
  return (
    <>
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
          <Button variant="primary" onClick={onPrimaryButtonClick}>
            {primaryButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
