import styled from 'styled-components';

export const BlogPostWrapper = styled.div`
  margin: 20px 500px;
  min-height: calc(100vh - 223px);
  font-family: 'Inconsolata', monospace;

  @media (max-width: 1024px) {
    margin: 20px;
  }
`;

export const Text = styled.p`
  font-weight: normal;
  font-size: 20px;
`;

export const Image = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 50%;
`;

export const ImageSubtitle = styled.p`
  text-align: center;
  font-size: 16px;
`;
