import axios from 'axios';
import { defaultDashboard, IGetDashboardResponse } from './types';
import { ICard, IDashboard, ILane, Lane } from '../containers/Dashboard/types';
import { LANES, VALID_GRAPH_LINKS } from '../containers/Dashboard/lanes';
import { CardDetails } from '../components/CardDetailsModal/types';

const baseUrl = 'https://funnel.fyi/api/v1';

export const getDashboard = (
  dashboardId: string,
  handleResponse: (response: IGetDashboardResponse) => void
) => {
  axios.get(`${baseUrl}/boards/${dashboardId}`).then(
    response => {
      const { columns, created, id, name, updated } = response.data;
      const lanes: Array<ILane> = [];

      // loop through the list of default lanes to find correct columns
      LANES.forEach(lane => {
        // filter columns
        const filteredColumns: Array<{ name: string; cards: Array<any> }> = columns.filter(
          (col: { name: string }) => col.name === lane.id
        );
        let column: { name?: string; cards: Array<any> };
        if (filteredColumns.length === 1) {
          [column] = filteredColumns;
          const cards: Array<ICard> = [];
          column.cards.forEach(card => {
            cards.push({
              id: card.id,
              title: card.title,
              description: card.description,
              label: card.location,
            });
          });
          column.cards = cards;
        } else {
          column = {
            cards: [],
          };
        }
        lanes.push({
          id: lane.id,
          title: lane.title,
          label: String(column.cards.length),
          cards: column.cards,
        });
      });

      const board: IDashboard = {
        id,
        boardName: name,
        created,
        updated,
        lanes,
      };

      handleResponse({
        data: board,
        loading: false,
      });
    },
    error => {
      handleResponse({
        ...defaultDashboard,
        loading: false,
        error,
      });
    }
  );
};

export const createNewBoard = async (email: string) => {
  const data = {
    email,
    boardName: 'default',
  };
  return axios.post(`${baseUrl}/boards`, JSON.stringify(data));
};

export const createCard = (
  card: ICard,
  laneId: Lane,
  boardId: string,
  refreshBoard: () => void
) => {
  const data = {
    column: laneId,
    title: card.title,
    description: card.description || null,
    location: card.label || null,
  };
  axios
    .post(`${baseUrl}/boards/${boardId}/cards`, JSON.stringify(data))
    .then(response => {
      refreshBoard();
    })
    .catch(error => {
      // eslint-disable-next-line no-alert
      alert('Something went wrong, please refresh!'); // TODO: Bring in actual alerting
    });
};

export const deleteCard = (cardId: string, boardId: string, refreshBoard: () => void) => {
  axios
    .delete(`${baseUrl}/boards/${boardId}/cards/${cardId}`)
    .then(response => {
      refreshBoard();
    })
    .catch(error => {
      console.log(error);
      // eslint-disable-next-line no-alert
      alert('Something went wrong, please refresh!'); // TODO: Bring in actual alerting
    });
};

export const moveCard = (
  cardId: string,
  fromLaneId: string,
  toLaneId: string,
  boardId: string,
  refreshBoard: () => void
) => {
  if (fromLaneId === toLaneId) return;
  const data = {
    from: fromLaneId,
    to: toLaneId,
  };
  axios
    .put(`${baseUrl}/boards/${boardId}/cards/${cardId}/move`, JSON.stringify(data))
    .then(response => {
      // TODO: Do something here
      console.debug('Successfully moved');
    })
    .catch(error => {
      console.log(error);
      alert('Something went wrong during card movement, please refresh'); // TODO: Bring in actual alerting
    });
};

export const getSankeyData = (
  boardId: string,
  handleResponse: ({ nodes, links }, loading: boolean) => void
) => {
  axios.get(`${baseUrl}/boards/${boardId}/sankey`).then(response => {
    const nodes: Array<{ id: string; color: string }> = [];
    LANES.forEach(lane => {
      const laneExists =
        response.data.movements.filter(
          movement => movement.from === lane.id || movement.to === lane.id
        ).length > 0;

      if (laneExists) {
        nodes.push({ id: lane.title, color: lane.color });
      }
    });

    const links: Array<{ source: string; target: string; value: number }> = [];
    response.data.movements.forEach(movement => {
      const { from, to } = movement;
      const allowedMovements = VALID_GRAPH_LINKS.filter(link => link.from === from);
      console.log(allowedMovements, from, to);
      if (allowedMovements[0].to.includes(to)) {
        links.push({
          source: LANES.filter(lane => lane.id === movement.from)[0].title,
          target: LANES.filter(lane => lane.id === movement.to)[0].title,
          value: movement.count,
        });
      }
    });

    handleResponse({ nodes, links }, false);
  });
};

export const getBarGraphData = (
  boardId: string,
  handleResponse: ({ data }, loading: boolean) => void
) => {
  axios.get(`${baseUrl}/boards/${boardId}/bar-graph`).then(response => {
    const data: Array<{ date: string; applications: string; applicationsColor: string }> = [];
    response.data.data.forEach(row => {
      data.push({
        date: row.date,
        applications: row.count,
        applicationsColor: 'hsl(222, 64%, 58%)',
      });
    });

    handleResponse({ data }, false);
  });
};

// TODO: Don't have an API endpoint to get card details
export const getCardDetails: (
  cardId: string,
  boardId: string,
  callback: (data: CardDetails) => void
) => void = (cardId, boardId, callback) => {
  // callback({
  //   id: 'response.data.id',
  //   title: 'response.data.title',
  //   description: 'response.data.description',
  //   location: 'response.data.location',
  //   comment: 'response.data.comment',
  // });
  axios.get(`${baseUrl}/boards/${boardId}/cards/${cardId}`).then(response => {
    callback({
      id: response.data.id,
      title: response.data.title,
      description: response.data.description,
      location: response.data.location,
      comment: response.data.comment,
    });
  });
};

export const updateCard = (laneId: string, card: ICard, boardId: string) => {
  const data = {
    column: laneId,
    title: card.title,
    description: card.description || null,
    location: card.label || null,
    comment: card.comment || null,
  };
  axios
    .put(`${baseUrl}/boards/${boardId}/cards/${card.id}`, JSON.stringify(data))
    .then(response => {})
    .catch(error => {
      // eslint-disable-next-line no-alert
      // alert('Something went wrong, please refresh!'); // TODO: Bring in actual alerting
    });
};
