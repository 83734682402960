import { IDashboard } from '../containers/Dashboard/types';

export interface IGetDashboardResponse {
  data: IDashboard;
  loading: boolean;
  error?: string;
}

export const defaultDashboard: IGetDashboardResponse = {
  data: {
    id: '',
    created: '',
    updated: '',
    boardName: '',
    lanes: [],
  },
  loading: true,
  error: '',
};
