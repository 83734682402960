import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../Home/Footer';
import { GettingStarted } from './posts/GettingStarted';

const POST_COMPONENT_MAP = {
  'getting-started': <GettingStarted />,
};

export const BlogPost: React.FC = () => {
  const { post } = useParams<{ post: string }>();
  const getReactComponentForPost: (postName: string) => React.Component = postName => {
    return POST_COMPONENT_MAP[postName];
  };
  return (
    <>
      <Header />
      {getReactComponentForPost(post)}
      <Footer />
    </>
  );
};
