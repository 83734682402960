import React from 'react';
import ReactLoader from 'react-loader-spinner';
import { Wrapper } from './styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { ILoaderProps } from './types';

const Loader: React.FC<ILoaderProps> = props => {
  return (
    <Wrapper>
      <ReactLoader type="Puff" color="#00BFFF" height={100} width={100} visible {...props} />
    </Wrapper>
  );
};

export default Loader;
