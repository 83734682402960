import React, { useEffect } from 'react';
import { BlogPostWrapper, Text, Image, ImageSubtitle } from './styles';
import addApplication from '../../../images/addApplication.gif';
import moveApplication from '../../../images/moveApplication.gif';

export const GettingStarted = () => {
  useEffect(() => {
    document.title = 'Getting started with funnel.fyi | Funnel.fyi';
  });

  return (
    <BlogPostWrapper>
      <h1>Getting started with Funnel.fyi</h1>
      <p>
        by <a href="https://twitter.com/iliekcomputers">Param Singh</a> on February 6, 2021
      </p>
      <hr />
      <Text>
        Hello, and welcome to <a href="https://funnel.fyi">funnel.fyi</a>!
      </Text>
      <Text>
        We&rsquo;re glad you&rsquo;ve taken the first step to superpower your job search with data.
        In this post, we&rsquo;ll explain how you can get the best out of funnel.fyi while applying
        for jobs.
      </Text>
      <Text>
        The first thing you want to do to use funnel.fyi is create your own dashboard. You can
        create dashboard from the <a href="https://funnel.fyi">home page</a>. Just enter your email
        address and click the &quot;Start your funnel&quot; button. You&rsquo;ll be redirected to an
        empty board straightaway.
      </Text>
      <Text>
        <b>IMPORTANT NOTE:</b> Please save the link of your dashboard carefully, as there is no
        automated way to get it back. If you&rsquo;ve lost the link to your board, get in touch with
        us on <a href="https://twitter.com/Funnelfyi">Twitter</a>, we&rsquo;ll be happy to help you
        out.
      </Text>
      <Text>
        Once you&rsquo;ve created your board, you can start adding your job applications to it. Each
        board has a number of lanes for different statuses of the job application process.
      </Text>
      <Text>
        We recommend adding all applications to the &quot;Applied&quot; lane and then moving them to
        their correct lanes, if you&rsquo;re adding applications you made in the past with a
        different status.
      </Text>
      <Image src={addApplication} alt="add application" />
      <ImageSubtitle>Here&rsquo;s how you add an application</ImageSubtitle>
      <Text>
        You can then move applications to different lanes when the status of an application changes.
        We keep track of each such movement across lanes and use that data to show you graphs about
        your job search.
      </Text>
      <Image src={moveApplication} alt="add application" />
      <ImageSubtitle>Here&rsquo;s what you do if an application changes status.</ImageSubtitle>
      <Text>
        You can then see all your graphs by clicking on the &quot;Graphs&quot; button on the top
        right corner of your dashboard.
      </Text>

      <Text>
        That&rsquo;s it! Hope you enjoy funnel.fyi, and if you have any feedback, feel free to get
        in touch with us on Twitter.
      </Text>
    </BlogPostWrapper>
  );
};
