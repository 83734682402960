export const LANES = [
  {
    id: 'lane_applied',
    title: 'Applied',
    color: 'hsl(145, 85%, 48%)',
  },
  {
    id: 'lane_rejected',
    title: 'Rejected',
    color: 'hsl(9, 85%, 48%)',
  },
  {
    id: 'lane_recruiter_screen',
    title: 'Recruiter Screen',
    color: 'hsl(199, 70%, 50%)',
  },
  {
    id: 'lane_coding_test',
    title: 'Coding Test',
    color: 'hsl(48, 85%, 54%)',
  },
  {
    id: 'lane_telephonic_interview',
    title: 'Telephonic Interview',
    color: 'hsl(283, 67%, 58%)',
  },
  {
    id: 'lane_onsite_interview',
    title: 'Onsite Interview',
    color: 'hsl(48, 67%, 58%)',
  },
  {
    id: 'lane_offer',
    title: 'Offer',
    color: 'hsl(120, 66%, 59%)',
  },
  {
    id: 'lane_accepted',
    title: 'Accepted',
    color: 'hsl(145, 85%, 48%)',
  },
];

export const VALID_GRAPH_LINKS = [
  {
    from: 'lane_accepted',
    to: [],
  },
  {
    from: 'lane_rejected',
    to: [],
  },
  {
    from: 'lane_offer',
    to: ['lane_accepted', 'lane_rejected'],
  },
  {
    from: 'lane_onsite_interview',
    to: ['lane_accepted', 'lane_rejected', 'lane_offer'],
  },
  {
    from: 'lane_telephonic_interview',
    to: ['lane_accepted', 'lane_rejected', 'lane_offer', 'lane_onsite_interview'],
  },
  {
    // TODO: we should probably allow coding test -> recruiter screen, but we do not right now.
    from: 'lane_coding_test',
    to: [
      'lane_accepted',
      'lane_rejected',
      'lane_offer',
      'lane_onsite_interview',
      'lane_telephonic_interview',
    ],
  },
  {
    from: 'lane_recruiter_screen',
    to: [
      'lane_accepted',
      'lane_rejected',
      'lane_offer',
      'lane_onsite_interview',
      'lane_telephonic_interview',
      'lane_coding_test',
    ],
  },
  {
    from: 'lane_applied',
    to: [
      'lane_accepted',
      'lane_rejected',
      'lane_offer',
      'lane_onsite_interview',
      'lane_telephonic_interview',
      'lane_coding_test',
      'lane_recruiter_screen',
    ],
  },
];
