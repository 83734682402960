import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  background: linear-gradient(135deg, #0079bf, #5067c5);
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1140px;
  padding-top: 7rem;
  padding-bottom: 5rem;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 480px) {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  margin-top: 3rem;
  @media (max-width: 480px) {
    margin-top: 0;
  }
`;

export const MainHeading = styled.h1`
  display: flex;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
  @media (max-width: 480px) {
    font-size: 32px;
    text-align: center;
  }
`;

export const Description = styled.p`
  display: flex;
  font-size: 1.5rem;
  font-weight: 300;
  @media (max-width: 480px) {
    font-size: 20px;
    text-align: center;
    line-height: 1.2;
  }
`;

export const HeroImage = styled.img`
  width: auto;
  @media (max-width: 480px) {
    height: 280px;
  }
`;

export const Row = styled.div`
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  form {
    width: 50%;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin-top: 1rem;
    flex-direction: column;
    form {
      width: 100%;
    }
  }
`;

export const StartButton = styled.div`
  width: 100%;
  button {
    width: 50%;
  }

  @media (max-width: 480px) {
    button {
      width: 100%;
    }
  }
`;

export const DemoLink = styled.a`
  width: 50%;
  text-align: center;
  color: white;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 5px;
  :hover {
    color: white;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
