import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-family: 'Inconsolata', monospace;
  height: 70px;
  width: 100%;
  background: linear-gradient(135deg, #0079bf, #5067c5);
  color: white;
  padding-left: 16px;
  @media (max-width: 480px) {
    height: 50px;
  }
`;

export const LogoSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: auto;
  height: 32px;
`;

export const LogoTitle = styled.div`
  font-size: 36px;
  line-height: 1;
  @media (max-width: 480px) {
    font-size: 25px;
    font-weight: 700;
  }
`;

export const BetaTag = styled.span`
  margin-left: 5px;
  font-size: 16px;
  margin-top: -10px;
`;

export const GraphIcon = styled.a`
  font-size: 20px;
  float: right;
  padding-right: 16px;
  color: white;

  svg {
    font-size: 16px;
  }

  :hover {
    color: white;
    cursor: pointer;
    text-decoration: none;
  }

  @media (max-width: 480px) {
    padding-right: 6px;

    svg {
      display: none;
    }
  }
`;

export const LogoWrapper = styled.a`
  display: flex;
  color: white;
  :hover {
    cursor: pointer;
    color: white;
    text-decoration: none;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
`;
