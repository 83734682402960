import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { Container, TwitterImage, Link, Heading, Copyright } from './styles';
import twitterImage from '../../../images/twitter.svg';

const Footer: React.FC = () => {
  return (
    <Container>
      <Heading>Contact us</Heading>
      <Link href="https://twitter.com/funnelfyi">
        <TwitterImage src={twitterImage} /> https://twitter.com/funnelfyi
      </Link>
      <Copyright>
        Copyright <FontAwesomeIcon icon={faCopyright} /> {new Date().getFullYear()}, funnel.fyi
      </Copyright>
    </Container>
  );
};

export default Footer;
