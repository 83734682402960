import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import Dashboard from './containers/Dashboard';
import Home from './containers/Home';
import Global from './containers/Global';
import { GraphPage } from './containers/GraphPage';
import { Blog } from './containers/Blog';
import NotFound from './components/NotFound';
import { BlogPost } from './containers/Blog/BlogPost';

const App: React.FC = () => (
  <Router>
    <div>
      <Suspense fallback="Loading.....">
        <Route exact path="/" component={Global} />
        <Route path="/dashboard/:dashboardId" component={Global} />
        <Route exact path="/" component={Home} />
        <Route exact path="/dashboard/:dashboardId/graphs" component={GraphPage} />
        <Route exact path="/dashboard/:dashboardId" component={Dashboard} />
        <Route exact path="/blog" component={Blog} />
        <Route path="/blog/:post" component={BlogPost} />
      </Suspense>
    </div>
  </Router>
);

export default App;
