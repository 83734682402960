import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components';
import { getBarGraphData } from '../../../apis/apis';
import Loader from '../../../components/Loader';

const Container = styled.section`
  height: auto;
`;

type BarGraphProps = {
  dashboardId: string;
};

export const BarGraph: React.FC<BarGraphProps> = ({ dashboardId }) => {
  const [graphData, setGraphData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const handleGraphData = (data, isLoading) => {
    setGraphData(data);
    setLoading(isLoading);
  };

  useEffect(() => {
    getBarGraphData(dashboardId, handleGraphData);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container style={{ height: `${graphData.data.length * 20 + 100}px` }}>
      <ResponsiveBar
        data={graphData.data}
        keys={['applications']}
        indexBy="date"
        margin={{ top: 50, right: 130, bottom: 50, left: 160 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        colors={{ scheme: 'category10' }}
        layout="horizontal"
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Applications',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Date',
          legendPosition: 'middle',
          legendOffset: -80,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#ffffff"
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        motionStiffness={90}
        motionDamping={15}
      />
    </Container>
  );
};
