import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import './index.css';
import App from './App';

Sentry.init({ dsn: 'https://c1d1e6c717ea4b559bd99b3aa5deeb0f@o536026.ingest.sentry.io/5654503' });

ReactDOM.render(
  <Sentry.ErrorBoundary fallback="An error has occurred, please try again later.">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
